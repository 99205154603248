<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('iam.adminDetails') }}
      </h2>
    </div>

    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        style="width: 100%; height: 30vh"
        class="flex justify-center"
        v-if="loading"
      >
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-if="record">
        <div
          class="
            flex flex-col
            lg:flex-row
            border-b border-gray-200
            dark:border-dark-5
            pb-5
            -mx-5
          "
        >
          <div
            class="
              flex flex-1
              px-5
              items-center
              justify-center
              lg:justify-start
            "
          >
            <div
              class="
                w-20
                h-20
                sm:w-24 sm:h-24
                flex-none
                lg:w-32 lg:h-32
                image-fit
                relative
              "
            >
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                data-action="zoom"
                :src="
                  record.avatar ? record.avatar : '/images/default-avatar.svg'
                "
              />
            </div>
            <div class="ml-5 mr-5">
              <div
                class="w-24 sm:w-40 sm:whitespace-normal font-medium text-lg"
              >
                {{ record.userName }}
              </div>
            </div>
          </div>
          <div
            class="
              mt-6
              lg:mt-0
              flex-1
              dark:text-gray-300
              px-5
              border-l border-gray-200
              dark:border-dark-5
              border-t
              lg:border-t-0
              pt-5
              lg:pt-0
            "
          >
            <div
              class="font-medium text-center lg:mt-3"
              :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
            >
              {{ i18n('iam.userDetails.contactDetails') }}
            </div>
            <div
              class="
                flex flex-col
                justify-center
                items-center
                lg:items-start
                mt-4
              "
            >
              <div
                class="truncate sm:whitespace-normal flex items-center gap-2"
              >
                <MailIcon class="w-4 h-4" />
                {{ record.email }}
              </div>
              <div
                class="
                  truncate
                  sm:whitespace-normal
                  flex
                  items-center
                  gap-2
                  mt-3
                "
              >
                <PhoneIcon
                  class="w-4 h-4"
                  :style="isRTL ? 'transform: rotateY(180deg)' : ''"
                />
                <span style="direction: ltr !important">{{
                  record.phoneNumber
                }}</span>
              </div>
            </div>
            <div
              class="font-medium text-center lg:mt-3"
              :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
            >
              {{ i18n('iam.userDetails.status') }}
            </div>
            <div
              class="
                flex flex-col
                justify-center
                items-center
                lg:items-start
                mt-4
              "
            >
              <div
                class="truncate sm:whitespace-normal flex items-center"
                :class="{
                  'text-theme-10 dark:text-theme-30': activated,
                  'text-theme-24': !activated
                }"
              >
                {{
                  activated ? i18n('common.activated') : i18n('common.disabled')
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      record: 'admin/form/record',
      loading: 'admin/form/findLoading'
    }),
    activated() {
      return this.record && this.record.status === 'enabled'
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.admins')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.admins')
    }
  },
  async mounted() {
    await this.doFind(this.id)
  },
  methods: {
    ...mapActions({
      doFind: 'admin/form/doFind'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
